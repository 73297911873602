body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inView {
  -webkit-transform: perspective(300px) rotateY(0deg) !important;
          transform: perspective(300px) rotateY(0deg) !important;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform-origin: center;
          transform-origin: center;
}

.show-more-button {
  text-decoration: none;
  border: 1px solid white;
  color: #9a9a9a;
  background-color: white;
  border-radius: 6px;
  padding: 6px;
}

.show-more-button:hover {
  background-color: #ebebeb;
  cursor: pointer;
}

.show-more-button:active {
  background-color: #9a9a9a;
  color: white;
  cursor: pointer;
}

.text-container {
  width: 400%;
}

/* Media Queries (Mobile First) */

@media only screen and (min-width: 768px) {
  #timeline-container {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  #heading {
    text-align: center;
  }

  #line-of-time {
    left: 50%;
  }

  .time-section {
    width: 50%;
    margin-bottom: 0px;
  }

  .text-container {
    width: 200%;
  }
}

#second-timeline-input:focus {
  outline: none;
  box-shadow: -2px 12px 12px -5px #9a9a9a;
}

.tf-button:hover {
  box-shadow: -8px 8px 18px -5px #9a9a9a !important;
  cursor: pointer;
}

.tf-button:active {
  background-color: #ebebeb !important;
  cursor: pointer;
}

.tf-button:focus {
  outline: none;
  box-shadow: -8px 8px 18px -5px #9a9a9a !important;
}

.loader,
.loader:before,
.loader:after {
  background: #9a9a9a;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #9a9a9a;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  margin-top: 50px;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@-webkit-keyframes dropIn {
  0% {
    -webkit-transform: translateY(-30%);
            transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1
  }
}

@keyframes dropIn {
  0% {
    -webkit-transform: translateY(-30%);
            transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1
  }
}

@-webkit-keyframes subtleDropIn {
  0% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1
  }
}

@keyframes subtleDropIn {
  0% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1
  }
}

@-webkit-keyframes fadeUp {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
    opacity: 0;
  }
}

@keyframes fadeUp {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-15%);
            transform: translateY(-15%);
    opacity: 0;
  }
}

@-webkit-keyframes dropUp {
  0% {
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes dropUp {
  0% {
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes changeHeight {
  0% {
      max-height: 0px;
  }
  100% {
      max-height: 18px;
  }
}

@keyframes changeHeight {
  0% {
      max-height: 0px;
  }
  100% {
      max-height: 18px;
  }
}

#timeline-button {
  -webkit-animation: 1s ease-out 0s 1 dropIn;
          animation: 1s ease-out 0s 1 dropIn;
}

.top-headings {
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */

  -webkit-animation: 1s ease-out 0s 1 dropUp;

          animation: 1s ease-out 0s 1 dropUp;
}

.visible-def {
  -webkit-animation: 0.5s ease-out 0s 1 subtleDropIn;
          animation: 0.5s ease-out 0s 1 subtleDropIn;
  /* transform: translateY(-20%);
  opacity: 1;
  transition: transform 0.5s; */
}

.invisible-def {
  -webkit-animation: 0.5s ease-out 0s 1 fadeUp;
          animation: 0.5s ease-out 0s 1 fadeUp;

}

#subtitle:hover {
  color: #8a8a8a !important
}

#link-input {
  -webkit-animation: 1s ease-out 0s 1 changeHeight;
          animation: 1s ease-out 0s 1 changeHeight;
}

#link-input:focus {
  outline: none;
  box-shadow: -8px 8px 18px -5px #9a9a9a !important;
}

#link-input:hover {
  box-shadow: -8px 8px 18px -5px #9a9a9a !important;
}

#home-button:hover {
  cursor: pointer;
}

/* Style all font awesome icons */
.fa {
  padding: 15px;
  font-size: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  margin-right: 15px;
  border-radius: 50%;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

/* Set a specific color for each brand */

/* Facebook */
.fa-facebook {
  background: #3B5998;
  color: white;
}

/* Twitter */
.fa-twitter {
  background: #55ACEE;
  color: white;
}
/* .inView {
  transform: perspective(300px) rotateY(0deg) !important;
  transition: transform 0.5s;
  transform-origin: center;
}

.show-more-button {
  text-decoration: none;
  border: 1px solid white;
  color: #9a9a9a;
  background-color: white;
  border-radius: 6px;
  padding: 6px;
}

.show-more-button:hover {
  background-color: #ebebeb;
  cursor: pointer;
}

.show-more-button:active {
  background-color: #9a9a9a;
  color: white;
  cursor: pointer;
} */


/* .text-container {
  width: 400%;
} */

/* Media Queries (Mobile First) */

/* @media only screen and (min-width: 768px) {
  #timeline-container {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  #heading {
    text-align: center;
  }

  #line-of-time {
    left: 50%;
  }

  .time-section {
    width: 50%;
    margin-bottom: 0px;
  }

  .text-container {
    width: 200%;
  }
} */

