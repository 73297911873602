.tf-button:hover {
  box-shadow: -8px 8px 18px -5px #9a9a9a !important;
  cursor: pointer;
}

.tf-button:active {
  background-color: #ebebeb !important;
  cursor: pointer;
}

.tf-button:focus {
  outline: none;
  box-shadow: -8px 8px 18px -5px #9a9a9a !important;
}
