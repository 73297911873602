@keyframes dropIn {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes subtleDropIn {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes fadeUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-15%);
    opacity: 0;
  }
}

@keyframes dropUp {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes changeHeight {
  0% {
      max-height: 0px;
  }
  100% {
      max-height: 18px;
  }
}

#timeline-button {
  animation: 1s ease-out 0s 1 dropIn;
}

.top-headings {
  user-select: none; /* Standard */
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */

  animation: 1s ease-out 0s 1 dropUp;
}

.visible-def {
  animation: 0.5s ease-out 0s 1 subtleDropIn;
  /* transform: translateY(-20%);
  opacity: 1;
  transition: transform 0.5s; */
}

.invisible-def {
  animation: 0.5s ease-out 0s 1 fadeUp;

}

#subtitle:hover {
  color: #8a8a8a !important
}

#link-input {
  animation: 1s ease-out 0s 1 changeHeight;
}

#link-input:focus {
  outline: none;
  box-shadow: -8px 8px 18px -5px #9a9a9a !important;
}

#link-input:hover {
  box-shadow: -8px 8px 18px -5px #9a9a9a !important;
}

#home-button:hover {
  cursor: pointer;
}
