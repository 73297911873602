.inView {
  transform: perspective(300px) rotateY(0deg) !important;
  transition: transform 0.5s;
  transform-origin: center;
}

.show-more-button {
  text-decoration: none;
  border: 1px solid white;
  color: #9a9a9a;
  background-color: white;
  border-radius: 6px;
  padding: 6px;
}

.show-more-button:hover {
  background-color: #ebebeb;
  cursor: pointer;
}

.show-more-button:active {
  background-color: #9a9a9a;
  color: white;
  cursor: pointer;
}

.text-container {
  width: 400%;
}

/* Media Queries (Mobile First) */

@media only screen and (min-width: 768px) {
  #timeline-container {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  #heading {
    text-align: center;
  }

  #line-of-time {
    left: 50%;
  }

  .time-section {
    width: 50%;
    margin-bottom: 0px;
  }

  .text-container {
    width: 200%;
  }
}

#second-timeline-input:focus {
  outline: none;
  box-shadow: -2px 12px 12px -5px #9a9a9a;
}
